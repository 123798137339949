import { StaticImage } from "gatsby-plugin-image"
import Flickity from "react-flickity-component"

import useWindowDimensions from "../../utils/useWindowDimensions"
import {
  clientLogosContainer,
  flipHorizontal,
  whiteShadow,
} from "./logo-strip.module.css"

const flickityOptions = {
  autoPlay: 2000,
  wrapAround: true,
  selectedAttraction: 0.01,
  friction: 0.2,
  cellAlign: "left",
  contain: true,
  pageDots: false,
  prevNextButtons: false,
  pauseAutoPlayOnHover: false,
}

export default function LogoStrip(props: {
  slider?: boolean
  fullWidth?: boolean
}) {
  const { fullWidth } = props
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 1080
  const containerWidth = isMobileUp ? "1080px" : "100%"
  const padding = fullWidth ? "" : "py-16"
  return (
    <section className={`${padding}`}>
      <div className="mx-auto" style={{ width: containerWidth }}>
        <div
          className={`${clientLogosContainer} rounded-xl relative opacity-80`}
        >
          <div
            className={`${whiteShadow} absolute left-0 top-0 w-20 bottom-0 z-10`}
          />
          <div
            className={`${whiteShadow} ${flipHorizontal} absolute right-0 top-0 w-20 bottom-0 z-10`}
          />
          <Flickity
            className="carousel grayscale contrast-150" // default ''
            elementType="div"
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            static // default false
          >
            <StaticImage
              className="w-14 md:w-20 mt-3 mx-8 flex items-center"
              src="../../images/client-logos/1.png"
              alt="weva"
              objectFit="contain"
            />
            <StaticImage
              objectFit="contain"
              className="w-14 md:w-28 h-12 mx-8"
              src="../../images/client-logos/2.png"
              alt="client magic motion media"
            />

            <StaticImage
              objectFit="contain"
              className="w-14 md:w-28 h-12 mx-8"
              src="../../images/client-logos/3.png"
              alt="lumiere"
            />
            <StaticImage
              className="w-14 md:w-28 mx-8 mt-2 flex items-center"
              src="../../images/client-logos/4.png"
              alt="weva"
            />
            <StaticImage
              objectFit="contain"
              className="w-14 md:w-24 mt-1 mx-8"
              src="../../images/client-logos/5.png"
              alt="client magic motion media"
            />
            <StaticImage
              objectFit="contain"
              className="w-14 md:w-36 mx-8 mt-1"
              src="../../images/client-logos/7.png"
              alt="client magic motion media"
            />
            <StaticImage
              className="w-14 md:w-24 mx-8 mt-2"
              src="../../images/client-logos/9.png"
              alt="weva"
            />

            <StaticImage
              className="w-14 md:w-12 mt-2 mx-8"
              src="../../images/client-logos/10.png"
              alt="weva"
            />
            <StaticImage
              className="w-14 md:w-10 mx-8 mt-2"
              src="../../images/client-logos/11.png"
              alt="weva"
            />
            <StaticImage
              className="w-14 md:w-12 mx-8 mt-2"
              src="../../images/client-logos/12.png"
              alt="weva"
            />
            <StaticImage
              className="w-14 md:w-16 mx-8 mt-2"
              src="../../images/client-logos/13.png"
              alt="weva"
            />
            <StaticImage
              className="w-14 md:w-40 mx-8 mt-5"
              src="../../images/client-logos/14.png"
              alt="weva"
            />
            <StaticImage
              className="w-14 md:w-40 mx-8 mt-7"
              src="../../images/client-logos/22.png"
              alt="weva"
            />
            <StaticImage
              className="w-14 md:w-24 mx-8 mt-5"
              src="../../images/client-logos/15.png"
              alt="weva"
            />
            <StaticImage
              className="w-14 md:w-32 mx-8 mt-2"
              src="../../images/client-logos/16.png"
              alt="weva"
            />
            <StaticImage
              className="w-14 md:w-32 mx-8 mt-2"
              src="../../images/client-logos/35.png"
              alt="weva"
            />
            <StaticImage
              className="w-14 md:w-32 mx-8 mt-2"
              src="../../images/client-logos/36.png"
              alt="weva"
            />
            <StaticImage
              className="w-14 md:w-32 mx-8 mt-2 "
              src="../../images/client-logos/37.png"
              alt="weva"
            />
          </Flickity>
          <Flickity
            className="carousel grayscale mt-4 md:mt-10" // default ''
            elementType="div"
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            static // default false
          >
            <StaticImage
              className="w-16 md:w-44 mx-8 mt-6"
              src="../../images/client-logos/17.png"
              alt="weva"
            />
            <StaticImage
              className="w-16 md:w-32 mx-8 mt-3"
              src="../../images/client-logos/18.png"
              alt="weva"
            />
            <StaticImage
              className="w-16 md:w-32 mx-8 mt-1"
              src="../../images/client-logos/19.png"
              alt="weva"
            />
            <StaticImage
              className="w-16 md:w-32 mx-8 mt-4"
              src="../../images/client-logos/20.png"
              alt="weva"
            />
            <StaticImage
              className="w-16 md:w-32 mx-8 mt-4"
              src="../../images/client-logos/21.png"
              alt="weva"
            />
            <StaticImage
              className="w-16 md:w-32 mx-8"
              src="../../images/client-logos/23.png"
              alt="weva"
            />
            <StaticImage
              className="w-16 md:w-32 mx-8"
              src="../../images/client-logos/24.jpg"
              alt="weva"
            />
            <StaticImage
              className="w-16 md:w-32 mx-8 mt-6"
              src="../../images/client-logos/25.png"
              alt="weva"
            />
            <StaticImage
              className="w-16 md:w-20 mx-8"
              src="../../images/client-logos/26.jpeg"
              alt="weva"
            />
            <StaticImage
              className="w-16 md:w-32 mx-8 mt-6"
              src="../../images/client-logos/27.png"
              alt="weva"
            />
            <StaticImage
              className="w-16 md:w-32 mx-8 mt-4"
              src="../../images/client-logos/28.webp"
              alt="weva"
            />
            <StaticImage
              className="w-16 md:w-32 mx-8"
              src="../../images/client-logos/29.png"
              alt="weva"
            />
             <StaticImage
              className="w-16 md:w-28 mx-8"
              src="../../images/client-logos/38.png"
              alt="weva"
            />
            <StaticImage
              className="w-16 md:w-32 mx-8"
              src="../../images/client-logos/30.png"
              alt="weva"
            />
            <StaticImage
              className="w-16 md:w-32 mx-8"
              src="../../images/client-logos/31.webp"
              alt="weva"
            />
            <StaticImage
              className="w-16 md:w-32 mx-8"
              src="../../images/client-logos/32.jpg"
              alt="weva"
            />
           
            <StaticImage
              className="w-16 md:w-24 mx-8"
              src="../../images/client-logos/39.png"
              alt="weva"
            />
            <StaticImage
              className="w-16 md:w-24 mx-8"
              src="../../images/client-logos/40.png"
              alt="weva"
            />
          </Flickity>
        </div>
      </div>
    </section>
  )
}
