import { Link } from "gatsby"
import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"
import { btn, btnPrimary } from "../../ds/button.module.css"
import { BlackArrowRight, WhiteArrowRight } from "../../ds/Shapes"
import QuoteIcon from "../../images/quote.svg"
import useWindowDimensions from "../../utils/useWindowDimensions"
import MagicWithUs from "../inner-page/MagicWithUs"
import AioData from "./aioData"
import AsiaBerlinData from "./asiaBerlinData"
import g2LiveData from "./g2LiveData"
import gitexGlobalData from './gitexGlobalData'
import GtechData from "./gtechData"
import JsconfData from "./jsconfData"
import MercedesBenzData from "./mercedesBenzData"
import MoreCaseStudy from "./MoreCaseStudy"
import NasscomData from "./nasscomData"
import RedSummitData from "./redSummitData"
import SaasboomiData from "./saasboomiData"
import ShowsOfIndiaData from "./showsOfIndiaData"
import StepConferenceData from "./stepConferenceData"
import TDPData from "./tdpData"
import UGCHub from "./ugc/ugcHub"
import UmagineData from "./umagineData"
import WowAwardsData from "./wowAwardsData"

export enum CASE_STUDY_TYPES {
  UMAGINE = "umagine",
  SAASBOOMI = "saasboomi",
  NASSCOM = "nasscom",
  WOWAWARDS = "wowawards",
  SHOWSOFINDIA = "showsofindia",
  ASIABERLIN = "asiaberlin",
  AIO = "aio",
  REDSUMMIT = "redsummit",
  MERCEDES_BENZ = "mercedesbenz",
  JSCONF = "jsconf",
  TDP = "tdp",
  GTECH = "gtech",
  STEPCONFERENCE = 'stepconference',
  GITEX_GLOBAL = 'gitexglobal',
  G2_LIVE = 'g2live',
}

function BlueTitle(props) {
  const { text, position } = props
  return (
    <h2 className={`font-semibold text-4xl mb-10 text-${position?? 'left' }`} style={{ color: "#7251F4" }} dangerouslySetInnerHTML={{ __html: text }}></h2>
  )
}

function Subheading(props) {
  const { text } = props
  return <h3 className="text-2xl font-medium text-black mb-12">{text}</h3>
}

function Info(props) {
  const { text, isList } = props;
  if (text.length === 0) return null;
  if (isList) {
    return (
      <ul className='list-disc'>
        {text?.list?.map((item, index) => (
          <li className="text-lg font-normal text-black mb-4" key={index}>{item}</li>
        ))}
      </ul>
    )
  } else {
    return (
      <p className="text-lg font-normal text-black mb-16" dangerouslySetInnerHTML={{ __html: text }} />
    )
  }

}

function Testimonial(props) {
  const { data } = props
  if (!data) return null
  return (
    <div className="border-y border-gray-300 text-center px-10 pt-3 pb-10">
      <img className="my-8 mx-auto" src={QuoteIcon} alt="" />
      <p className="my-8 text-xl font-medium leading-relaxed" dangerouslySetInnerHTML={{ __html: data?.message }}></p>
      <div
        className="flex w-max mx-auto py-5 px-6 rounded-2xl"
        style={{ backgroundColor: "#F5F2FF" }}
      >
        <div className="w-14 overflow-hidden rounded-full">{data?.img}</div>
        <div className="ml-5 flex flex-col justify-center">
          <h5 className="font-bold text-sm text-black text-left">
            {data?.name}
          </h5>
          <p
            className="text-xs font-normal text-black text-left"
            dangerouslySetInnerHTML={{ __html: data?.info }}
          ></p>
        </div>
      </div>
    </div>
  )
}

function Video (props) {
  const { url } = props;
  return (
    <iframe width="100%" height="415" src={url} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
  )
}

type CaseStudyProps = {
  type: CASE_STUDY_TYPES
}

export default function CaseStudy(props: CaseStudyProps) {
  const { type } = props
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 1080
  const containerWidth = isMobileUp ? "1080px" : "100%"
  const heroBgHeight = isMobileUp ? "110vh" : "115vh"

  let caseStudyData: any = {}
  switch (type) {
    case CASE_STUDY_TYPES.UMAGINE:
      caseStudyData = UmagineData
      break
    case CASE_STUDY_TYPES.SAASBOOMI:
      caseStudyData = SaasboomiData
      break
    case CASE_STUDY_TYPES.NASSCOM:
      caseStudyData = NasscomData
      break
    case CASE_STUDY_TYPES.WOWAWARDS:
      caseStudyData = WowAwardsData
      break
    case CASE_STUDY_TYPES.SHOWSOFINDIA:
      caseStudyData = ShowsOfIndiaData
      break
    case CASE_STUDY_TYPES.ASIABERLIN:
      caseStudyData = AsiaBerlinData
      break
    case CASE_STUDY_TYPES.AIO:
      caseStudyData = AioData
      break
    case CASE_STUDY_TYPES.REDSUMMIT:
      caseStudyData = RedSummitData
      break
    case CASE_STUDY_TYPES.MERCEDES_BENZ:
      caseStudyData = MercedesBenzData
      break
    case CASE_STUDY_TYPES.JSCONF:
      caseStudyData = JsconfData
      break
    case CASE_STUDY_TYPES.TDP:
      caseStudyData = TDPData
      break
    case CASE_STUDY_TYPES.GTECH:
      caseStudyData = GtechData
      break
    case CASE_STUDY_TYPES.STEPCONFERENCE:
      caseStudyData = StepConferenceData;
      break;
    case CASE_STUDY_TYPES.GITEX_GLOBAL:
      caseStudyData = gitexGlobalData;
      break;
    case CASE_STUDY_TYPES.G2_LIVE:
      caseStudyData = g2LiveData;
      break;
    default:
      break
  }

  return (
    <div className="">
      <div
        className="absolute left-0 right-0 top-0 z-10"
        style={{ height: heroBgHeight, backgroundColor: caseStudyData?.color }}
      ></div>
      <section className="pt-10 relative z-20">
        <div className="px-5 md:px-0 mx-auto" style={{ width: containerWidth }}>
          <div className="md:flex">
            <div className="md:w-1/2 flex flex-col justify-center text-left">
              <Link
                to="https://premagic.com/case-study/"
                className={`${btn} text-xs mt-5 relative group w-max -ml-4`}
              >
                <BlackArrowRight className="rotate-180 transition duration-300 ease-in-out group-hover:-translate-x-2" />
                <span className="ml-2 text-black">Back to Case Studies</span>
              </Link>
              <h1
                className="text-3xl md:text-5xl font-bold md:leading-snug mb-12 text-black"
                dangerouslySetInnerHTML={{ __html: caseStudyData?.heroTitle }}
              ></h1>
              <Link
                to="/request-a-demo/"
                className={`${btn} ${btnPrimary} text-xs md:mt-5 mb-20 md:mb-0 relative w-max group`}
              >
                <span className="mr-4 ml-2">Talk to our expert</span>
                <WhiteArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2 mr-2" />
              </Link>
            </div>
            <div className="md:w-1/2 -ml-12 md:ml-0 flex items-center">
              {caseStudyData?.heroImage}
            </div>
          </div>

          <div className="rounded-2xl md:shadow-xl md:p-10 md:p-20 bg-white mt-20">
            <div className="flex justify-between flex-wrap md:flex-nowrap">
              {caseStudyData?.metrics?.values?.map((item, index) => (
                <div className="mb-10 md:mb-0 text-center md:text-left w-1/2 md:w-auto">
                  <h4 className="font-medium text-4xl text-black">
                    <CountUp end={Number(item.value.replaceAll(",", ""))}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start}>
                          <span ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                  </h4>
                  <p className="text-sm font-normal text-black mt-2">
                    {item.label}
                  </p>
                </div>
              ))}
            </div>

            <div className="mt-10 md:mt-24 md:flex h-full">
              <div className="md:w-[80%] mx-auto">
                <h2 className="text-black text-4xl font-semibold">
                  {caseStudyData?.metrics?.title}
                </h2>
                <p className="mt-10 leading-relaxed text-black mb-10" dangerouslySetInnerHTML={{__html:caseStudyData?.metrics?.content}}></p>
                <div className="rounded-2xl bg-purple-100 p-10 top-20 flex flex-row items-top justify-between mb-10 gap-10 flex-col sm:flex-row">
                  <div className="w-full md:w-1/3">
                    <h4 className="text-lg text-black font-semibold">Host</h4>
                    <p className="text-sm text-black font-normal mt-1">
                      {caseStudyData?.metrics.host}
                    </p>
                  </div>
                  <div className="w-full md:w-1/3">
                    <h4 className="text-lg text-black font-semibold">
                      Organizer
                    </h4>
                    <p className="text-sm text-black font-normal mt-1" dangerouslySetInnerHTML={{__html:caseStudyData?.metrics.organizer}}></p>
                  </div>
                 <div className="w-full md:w-1/3">
                  <h4 className="text-lg text-black font-semibold">
                      Industry
                    </h4>
                    <p className="text-sm text-black font-normal mt-1">
                      {caseStudyData?.metrics.industry}
                    </p>
                 </div>
                </div>
                <p className="mt-10 leading-relaxed text-black mb-10" dangerouslySetInnerHTML={{__html:caseStudyData?.metrics?.content2}}></p>
                {caseStudyData?.metrics?.history && 
                  caseStudyData?.metrics?.history.map(({title, content, image}, index) => (
                    <div key={index} className="mb-12">
                      <h4 className="text-2xl font-bold mb-4 text-black">{title}</h4>
                     <div className="my-8">{image}</div>
                      <p dangerouslySetInnerHTML={{__html:content}}></p>
                    </div> 
                  ))
                }
                <p className="text-xl text-black mt-10 font-medium">
                  {caseStudyData?.metrics?.footer}
                </p>
              </div>
            </div>
          </div>
          
          <div className="mt-20 md:mt-40 mb-28">
            {caseStudyData?.images[0]?.img}
          </div>
          {caseStudyData.caseStudyData.map(item => (
            <div>
              <div className="md:px-44 md:mb-40">
                <BlueTitle text={item.title} />
                <Subheading text={item.subheading} />
                <Info text={item.info1} isList={item?.info1?.list}/>
                <Testimonial data={item?.testimonial || null} />
                <div className="mt-10" />
                <Info text={item?.info2 || ""} isList={item?.info2?.list}/>
                {item?.video ? <Video url={item?.video}/> : item.image}
                {item.gridViewData && (
                  <div className="flex flex-wrap">
                    {item.gridViewData.map(data => (
                      <div className="w-full md:w-1/2 mb-20 md:mb-10 px-10">
                        <div className="flex items-center">
                          {data.icon}
                          <h4 className="ml-3 text-lg font-medium" dangerouslySetInnerHTML={{__html: data.title}}></h4>
                        </div>
                        <p className="mt-10 text-sm" dangerouslySetInnerHTML={{__html: data.description}}></p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {item.title === "The Solution" && (
                <div className="mb-28">{caseStudyData?.images[1]?.img}</div>
              )}
            </div>
            ))}
            <div className="my-20">
              <BlueTitle text={caseStudyData?.successMetrics?.title} position="center" />
              <p className="text-center" dangerouslySetInnerHTML={{__html: caseStudyData?.successMetrics?.description}}></p>
              <div className={`my-20 grid grid-cols-1 gap-4 lg:grid-cols-${caseStudyData?.successMetrics?.gridValues?.length > 4 ? '4' : '3'} md:grid-cols-3 sm:grid-cols-1`}>
              {caseStudyData?.successMetrics?.gridValues?.map((metric) => {
                const numericValue = parseFloat(metric.value?.replace(/[^\d.-]/g, "")); 
                const isPercentage = metric.value?.includes("%");
                const isCurrency = metric.value?.includes("$");
                return (
                  <div
                    key={metric.id}
                    className="border p-10 rounded-2xl text-center shadow-sm"
                  >
                    <span className="inline-block text-lg font-semibold mb-4">
                      {metric.label}
                    </span>
                    <h2 className="text-4xl font-bold text-black">
                    {isCurrency && " $"}
                      <CountUp end={numericValue} duration={2} decimals={isPercentage ? 1 : 0}>
                        {({ countUpRef, start }) => (
                          <VisibilitySensor onChange={(isVisible) => isVisible && start()}>
                            <span ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      {isPercentage && "%"}
                    </h2>
                  </div>
              );
            })}
            </div>
          </div>
          <div className="my-20">
            <h2 className="text-black text-center mb-16 text-4xl md:text-5xl font-bold">
              Sneak peek of the event
            </h2>
            {caseStudyData.gallery}
          </div>
        </div>
        {caseStudyData?.UGCData && <UGCHub UGCData={caseStudyData?.UGCData}/>}
        <MoreCaseStudy currentPage={type} itemsToShow={3} />
        <MagicWithUs />
      </section>
    </div>
  )
}