
import CaseStudy, { CASE_STUDY_TYPES } from '../../../components/case-study/CaseStudy';
import Header from '../../../components/Header';
import Layout from '../../../components/Layout';
import ThumbnailImage from '../../../images/thumbnails/gitex_thumb.png';

export function Index() {
  return (
    <Layout 
    title="Learn how Premagic converted the attendees at GITEX GLOBAL into content creators"
    description="GITEX GLOBAL stands out as a major force for innovation and opportunity. As the world’s largest tech and startup event, GITEX has become known for showcasing the latest technologies, making big business connections, and shaping the future of digital innovation. Every year, it brings together thousands of exhibitors, speakers, and attendees worldwide, creating an unmatched space for technology and business growth."
    metaImage={ThumbnailImage}
    >
      <Header />
      <CaseStudy type={CASE_STUDY_TYPES.GITEX_GLOBAL} />
    </Layout>
  );
}

export default Index;