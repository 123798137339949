import { Link } from "gatsby";
import useWindowDimensions from "../../utils/useWindowDimensions";

const FeatureDescription = ({ data }) => {
  const { descriptions, link } = data
  if (Array.isArray(descriptions)) {
    return (
      <>
        {descriptions.map((description, index) => (
          <div className="flex mt-10" key={index}>
            {data.icon && (
              <div className="mr-5">
                <data.icon />
              </div>
            )}
            <div>
              <p className="text-base text-black pr-5">{description}</p>
            </div>
          </div>
        ))}
      </>
    );
  }else{
    return (
      <div className="flex mt-4 md:mt-10">
        <div>
          <p className="text-base text-black pr-5 text-sm md:text-lg">{descriptions}</p>
          <Link to={link} className="inline-block mt-6 text-white py-2 md:py-4 px-4 md:px-8 rounded-lg text-sm md:text-lg"style={{ border: "1px solid #7252F0", color: "#7252F0" }}>Learn More</Link>
        </div>
      </div>
    );
  }
};
const HeadTitle = ({ isHome }) => {
  if(isHome){
    return (
      <h1 className="text-center font-bold text-2xl sm:text-3xl md:text-5xl mt-10 leading-tight text-black leading-snug capitalize">
      <div className="relative mx-2 inline z-10">
        Ultimate hacks for serious {" "}
          <div className="relative inline-block z-5">
            <span
              style={{ backgroundColor: "#61F2C2" }}
              className="z-1 block absolute left-0 right-0 h-1.5 rounded-xl bottom-1"
            ></span>
            <div className="relative z-2"> event marketers </div>
          </div>
        </div>
      </h1>
    )
  }else{
    return (
      <h1 className="text-center font-bold text-2xl sm:text-3xl md:text-5xl mt-10 leading-tight text-black leading-snug capitalize">
        Ignite event magic with the perfect corporate 
        <div className="relative mx-2 inline z-10">
            <div className="relative inline-block z-5">
              <span
                style={{ backgroundColor: "#61F2C2" }}
                className="z-1 block absolute left-0 right-0 h-1.5 rounded-xl bottom-1"
              ></span>
              <div className="relative z-2">event marketing tool</div>
            </div>
          </div>
        </h1>
    )
  }
}

export function MainFeaturesSection({features, isHome=false}) {
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 768
  if (isMobileUp) {
    return (
      <div className="pm-container mx-auto px-6 my-24">
       <HeadTitle isHome={isHome}/>
        <p className="text-black text-center mt-10 mb-10">
        One platform, endless digital touch points, maximum engagement.
        </p>
        {features.map((item, idx) => (
          <div
            className={`flex my-20 ${idx % 2 === 0 ? "" : "flex-row-reverse"}`}
          >
            <div className="w-1/2">{item?.image}</div>
            <div
              className={`w-1/2 flex flex-col justify-center ${
                idx % 2 === 0 ? "pl-20" : "pr-20"
              }`}
            >
              {isHome &&<span className="block mb-10 text-md font-bold">Hack #{idx+1}</span>}
              <h2 className="text-black text-3xl font-semibold leading-snug" dangerouslySetInnerHTML={{ __html: item.title }}></h2>
              <FeatureDescription data={item}/>

            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    
    <div className="pm-container mx-auto px-6 my-8">
      <HeadTitle isHome={isHome}/>
      <p className="text-black text-base text-center mt-4 mb-10">
      One platform, endless digital touch points, maximum engagement.  
      </p>
      {features.map((item, idx) => (
        <div className="my-20" key={idx}>
          <div className="mb-6 md:mb-10 overflow-hidden rounded-2xl">{isMobileUp ? item?.image : item?.mob_image}</div>
          <div className="pt-2">
          {isHome && isMobileUp &&<span className="block mb-10 text-md font-bold">Hack #{idx+1}</span>}
            <h2 className="text-black text-xl font-semibold leading-snug pr-5" dangerouslySetInnerHTML={{ __html: item.title }}></h2>
            <FeatureDescription data={item}/>
          </div>
        </div>
      ))}
    </div>
  )
}

export default MainFeaturesSection
