import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export enum TESTIMONIAL_TYPES {
  CORPORATES = "CORPORATES",
  POLITICAL = "POLITICAL",
}

const TESTIMONIALS: Array<{
  type: TESTIMONIAL_TYPES
  logo: React.ReactNode
  message: string
  profile: React.ReactNode
  name: string
  designation: string
  company: string
  color: string
  link?: string
}> = [
  {
    type: TESTIMONIAL_TYPES.CORPORATES,
    logo: (
      <StaticImage
        src="../images/client-logos/33.png"
        className="w-40"
        alt=""
      />
    ),
    message: `“From a marketing perspective, one thing we're really focused on is keeping our audience engaged even beyond the conference dates, and this is where Premagic really helped us.“`,
    profile: (
      <StaticImage
        src="../images/case-study/stepconference/avatar.png"
        class="rounded-full"
        width={40}
        alt="asd"
      />
    ),
    name: "Dana Sakr",
    designation: "Marketing Manager",
    company: "Step Conference",
    color: "#c3cdff",
    link:'/case-study/step-conference'
  },
  {
    type: TESTIMONIAL_TYPES.CORPORATES,
    logo: (
      <StaticImage
        src="../images/client-logos/34.png"
        className="w-40"
        alt=""
      />
    ),
    message: `“ Premagic's partnership with GITEX GLOBAL brought a new level of excitement to the event, making it an unforgettable celebration of creativity and innovation. “`,
    profile:  (
      <StaticImage
        src="../images/case-study/gitex-global/shreya.png"
        class="rounded-full"
        width={40}
        alt="asd"
      />
    ),
    name: 'Shreya Shyamsunder',
    designation: "Marketing Executive",
    company: "Dubai World Trade Center",
    color: "#FFF1EF",
    link:'/case-study/gitex-global'

  },
  {
    type: TESTIMONIAL_TYPES.CORPORATES,
    logo: (
      <StaticImage
        src="../images/client-logos/14.png"
        className="w-40"
        alt=""
      />
    ),
    message: `“Our aim was to engage the audience, foster a positive brand association for sponsors, and find a comprehensive event marketing solution for ABS2023.“`,
    profile: (
      <StaticImage
        src="../images/case-study/asiaberlin/avatar.png"
        class="rounded-full"
        width={40}
        alt="asd"
      />
    ),
    name: "Swantje Gies",
    designation: "Managing Director",
    company: "AsiaBerlin Forum",
    color: "#F5F2FF",
    link:'/case-study/asiaberlin'

  },
  {
    type: TESTIMONIAL_TYPES.CORPORATES,
    logo: (
      <StaticImage
        src="../images/client-logos/18.png"
        className="w-40"
        alt=""
      />
    ),
    message: `“ Premagic's partnership with UmagineChennai brought a new level of excitement to the event, making it an unforgettable celebration of creativity and innovation. “`,
    profile: (
      <StaticImage
        src="../images/client-photos/kazhal.png"
        class="rounded-full"
        width={40}
        alt="asd"
      />
    ),
    name: "Kazhal Vendhan",
    designation: "Manager",
    company: "The Confederation of Indian Industry",
    color: "#F5F2FF",
  },
  {
    type: TESTIMONIAL_TYPES.CORPORATES,
    logo: (
      <StaticImage
        src="../images/client-logos/20.png"
        className="w-40"
        alt=""
      />
    ),
    message: `“ Premagic delivered instant photos to our delegates, which was an unprecedented experience for us. It added a remarkable touch to our event, making it truly memorable. We're thrilled with the results! “`,
    profile: (
      <StaticImage
        src="../images/client-photos/parth.png"
        class="rounded-full"
        width={40}
        alt="asd"
      />
    ),
    name: "Parth Premi",
    designation: "Sponsorship Manager",
    company: "NASSCOM",
    color: "#FFF1EF",
  },
  {
    type: TESTIMONIAL_TYPES.CORPORATES,
    logo: (
      <StaticImage
        src="../images/client-logos/15.png"
        className="w-40"
        alt=""
      />
    ),
    message: `“ Managing event photos was once a headache, with guest requests for immediate access. Thanks to Premagic, it's now hassle-free! We're excited to use it for all our future events! “`,
    profile: (
      <StaticImage
        src="../images/client-photos/malavika.jpeg"
        class="rounded-full"
        width={40}
        alt="asd"
      />
    ),
    name: "Malavika Velayanikal",
    designation: "Director - Initiatives & Events",
    company: "SaaSBOOMi",
    color: "#ECFFF9",
  },
]

export default TESTIMONIALS
