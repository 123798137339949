import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { footer } from "./footer.module.css"

export default function Footer() {
  const isBrowser = () => typeof window !== "undefined"

  const isUAEDomain = isBrowser() && window.location.host.includes("ae")
  const showUAEAddress = true || isUAEDomain;
  return (
    <footer>
      <div className={footer}>
        <div className="pm-container mx-auto p-5 pt-10">
          <div className="flex flex-wrap md:flex-nowrap">
            <div className="md:w-1/2 md:pr-40">
              <div>
                <StaticImage
                  placeholder="blurred"
                  src="../images/logo/premagic-logo@2xl.svg"
                  width={150}
                  className="-ml-2.5"
                  alt="logo"
                />
                <p className="my-5 text-sm leading-6">
                  Premagic is an AI-based event tech platform that boosts
                  organic event marketing. We're on a mission to turn every
                  attendee into an event marketer. As a comprehensive event
                  marketing and experience tool, Premagic covers all stages from
                  pre-event to post-event, offering solutions to boost
                  engagement, visibility, and attendee satisfaction organically.
                </p>
                <div className="flex">
                  <div className={`mr-2`}>
                    <a
                      href="https://www.linkedin.com/company/premagic/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <StaticImage
                        placeholder="blurred"
                        className="mt-2"
                        src="../images/social-media-icons/linkedin.png"
                        height={24}
                        alt="twitter"
                      />
                    </a>
                  </div>
                  <div className={`mr-4`}>
                    <a
                      href="https://www.facebook.com/premagicdotcom/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <StaticImage
                        placeholder="blurred"
                        className="mt-3"
                        src="../images/social-media-icons/fb.png"
                        height={16}
                        alt="fb"
                      />
                    </a>
                  </div>
                  <div className={`mr-4`}>
                    <a
                      href="https://www.instagram.com/premagicdotcom/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <StaticImage
                        placeholder="blurred"
                        className="mt-3"
                        src="../images/social-media-icons/insta.png"
                        height={16}
                        alt="insta"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://www.youtube.com/channel/UCyhi-GvR0rrBq639Z8uWtqw/videos"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <StaticImage
                        placeholder="blurred"
                        className="mt-2"
                        src="../images/social-media-icons/youtube.png"
                        height={24}
                        alt="twitter"
                      />
                    </a>
                  </div>
                </div>
              </div>
              {showUAEAddress ? (
                <div>
                  <h4 className="text-black text-sm font-bold mt-10 mb-5">
                    OFFICE
                  </h4>
                  <p className="text-black text-xs">
                    PREMAGIC CO LLC
                    <br />
                    Allianc Insurance Blg,
                    <br />
                    Al-Muraqqabat Dubai
                    <br />
                    United Arab Emirates
                  </p>
                </div>
              ) : (
                <div>
                  <h4 className="text-black text-sm font-bold mt-10 mb-5">
                    OFFICE
                  </h4>
                  <p className="text-black text-xs">
                    Wellbeing Studios Pvt Ltd
                    <br />
                    DOOR NO. 17/786 - M, Chakkamadathil Arcade,
                    <br />
                    Kandassankadavu P.O, Thrissur
                    <br />
                    Kerala 680613
                  </p>
                </div>
              )}
              <div>
                <Link to="https://premagic.com/gdpr/">
                  <StaticImage
                    placeholder="blurred"
                    src="../images/gdpr_logo.png"
                    width={150}
                    className="rounded-lg mt-3"
                    style={{ backgroundColor: "#1F51E2" }}
                    alt="logo"
                  />
                </Link>
              </div>
            </div>

            <div className="mt-12 mb-20 w-full md:w-1/2">
              <div className="flex justify-between flex-wrap">
                <div>
                  <h4 className="text-sm font-bold mb-5 text-black">COMPANY</h4>
                  <ul>
                    <li className="mb-2">
                      <Link
                        className="text-sm  text-black"
                        to={`https://premagic.${
                          isUAEDomain ? "ae" : "com"
                        }/about-us/`}
                      >
                        About Us
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        className="text-sm   text-black"
                        to={`https://premagic.${
                          isUAEDomain ? "ae" : "com"
                        }/contact-us/`}
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li className="mb-2">
                      <a
                        className="text-sm   text-black"
                        href="https://angel.co/company/premagic/jobs"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Careers
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        className="text-sm text-black"
                        href="https://blog.premagic.com/"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Blog
                      </a>
                    </li>
                  </ul>
                </div>

                <div>
                  <h4 className="text-sm font-bold mb-5 text-black">SALES</h4>
                  <ul>
                    <li className="mb-2">
                      {isUAEDomain ? (
                        <a
                          className="text-sm   text-black"
                          href="mailto:sales@premagic.ae?Subject=Enquiry"
                        >
                          sales@premagic.ae
                        </a>
                      ) : (
                        <a
                          className="text-sm   text-black"
                          href="mailto:sales@premagic.com?Subject=Enquiry"
                        >
                          sales@premagic.com
                        </a>
                      )}
                    </li>
                    <li className="mb-2">
                      <a
                        className="text-sm  text-black"
                        href="tel:+971529453727"
                      >
                        +971 52 945 3727
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        className="text-sm  text-black"
                        href="tel:+971585337576"
                      >
                        +971 58 533 7576
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        className="text-sm  text-black"
                        href="tel:+919895096532"
                      >
                        +91 9895096532
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h4 className="text-sm font-bold mb-5 text-black">SUPPORT</h4>
                  <ul>
                    <li className="mb-2">
                      {isUAEDomain ? (
                        <a
                          className="text-sm   text-black"
                          href="mailto:support@premagic.ae?Subject=Help"
                        >
                          support@premagic.ae
                        </a>
                      ) : (
                        <a
                          className="text-sm   text-black"
                          href="mailto:support@premagic.com?Subject=Help"
                        >
                          support@premagic.com
                        </a>
                      )}
                    </li>
                    <li className="mb-2">
                      {isUAEDomain ? (
                        <a
                          className="text-sm  text-black"
                          href="tel:+971529453727"
                        >
                          +971 52 945 3727
                        </a>
                      ) : (
                        <a
                          className="text-sm  text-black"
                          href="tel:+918106176986"
                        >
                          +91 8106176986
                        </a>
                      )}
                    </li>
                    <li className="mb-2">
                      <a
                        className="text-sm  text-black"
                        href="tel:+918714252888"
                      >
                        +91 8714252888
                      </a>
                    </li>
                    <li className="mb-2">
                      <Link className="text-sm   text-black" to="/faq/">
                        FAQ's
                      </Link>
                    </li>
                    <li className="mb-2">
                      <a
                        className="text-sm   text-black"
                        href="//premagic.freshdesk.com"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Support
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        className="text-sm   text-black"
                        href="//statuspage.freshping.io/2895-Premagic"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Status
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {showUAEAddress ? (
            <p className="text-black text-sm pt-10 text-center w-full">
              Copyright © {new Date().getFullYear()} PREMAGIC CO LLC
              <br />
            </p>
          ) : (
            <p className="text-black text-sm pt-10 text-center w-full">
              Copyright © {new Date().getFullYear()} Wellbeing Studios Pvt. Ltd.{" "}
              <br /> Premagic
            </p>
          )}
          <p className="text-black text-xs text-center w-full mb-10 mt-2 flex justify-center justify-items-center">
            <a
              className="text-black text-black"
              href="https://blog.premagic.com/privacy/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Privacy
            </a>
            <span className="block px-1 text-gray-300">|</span>
            <Link
              className="text-black"
              to="/refund-policy/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Refund Policy
            </Link>
            <span className="block px-1 text-gray-300">|</span>
            <a
              className="text-black"
              href="https://blog.premagic.com/terms/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Terms & Conditions
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}