export const PRICING_PASSWORD_HASH = "8ba74f6473976e42b44aed4da68f9cc9e6679d80917edfc44e44cd394504b1a8"

export const validatePassword = async (password: string): Promise<boolean> => {
  const encoder = new TextEncoder()
  const data = encoder.encode(password)
  const hashBuffer = await crypto.subtle.digest('SHA-256', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
  return hashHex === PRICING_PASSWORD_HASH
}
