import { Link } from "gatsby"
import TESTIMONIALS from "../../data/testimonials"
import { BlackArrowRight } from "../../ds/Shapes"
import { lightText } from "./customer-section.module.css"

function TestimonialsCard(props) {
  const { data } = props

  return (
    <div className="px-7 md:px-7 w-full h-full">
      <div
        className="p-8 md:px-16 w-full h-full text-center rounded-2xl flex flex-col justify-between"
        style={{ backgroundColor: `${data.color}` }}
      >
        <div className="my-5">{data?.logo}</div>
        <p
          className={`${lightText} py-8 overflow-hidden text-base md:text-xl leading-snug mb-10`}
        >
          {data?.message}{" "}
        </p>
        <div className={`flex items-center flex-col xl:flex-row ${data.profile ? 'justify-between' : 'justify-end'}`}>
          {data.profile &&<div className="bg-white w-fit p-5 rounded-xl flex mt-auto">
            <div className="flex items-center">
              <div className="rounded-full w-10 h-10 mr-5 overflow-hidden">
                {data.profile}
              </div>
              <div>
                <h4 className="text-sm font-extrabold text-black text-left">
                  {data?.name}
                </h4>
                <p className="text-sm text-left">{data?.designation || ""}</p>
                <p className="text-sm text-left">{data?.company || ""}</p>
              </div>
            </div>
          </div>}
          <div className="flex items-center justify-center hover:opacity-80 mt-10 xl:mt-0">
            <Link to={data?.link} className="flex justify-between items-center group">
              <span className="mr-2 text-sm font-semibold text-black">Read more</span>
              <BlackArrowRight 
                className="transform transition-transform group-hover:translate-x-2 delay-100 duration-300 ease-in-out" 
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export enum REVIEW_TYPES {
  INVITE = "invite",
  COMMON = "common",
  CLIENT_APPS = "client-apps",
}

type Props = {
  reviewType?: REVIEW_TYPES
  isHomeScreen?:boolean
}
export default function CustomerFeedback(props: Props) {
  return (
    <section id="customers">
      <div className="pm-container mx-auto px-6 my-24 relative overflow-hidden">
        {!props?.isHomeScreen && <h1 className="text-center font-bold text-5xl mt-10 leading-tight text-black leading-snug mb-20">
          Don’t take our word for it,
          <br /> hear it from our customers
        </h1>}
        <TestimonialsCard data={TESTIMONIALS[0]} />
        <div className="md:flex mt-10 content-stretch">
          <div className="md:w-6/12 mb-10 md:mb-0">
            <TestimonialsCard data={TESTIMONIALS[1]} />
          </div>
          <div className="md:w-6/12">
            <TestimonialsCard data={TESTIMONIALS[2]} />
          </div>
        </div>
      </div>
    </section>
  )
}
