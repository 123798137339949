import React, { useState } from "react"
import { validatePassword } from "../utils/PasswordUtils"
import { input } from "../ds/form.module.css"

interface PasswordScreenProps {
  onSuccess: () => void
}

export const PasswordScreen: React.FC<PasswordScreenProps> = ({ onSuccess }) => {
  const [password, setPassword] = useState("")
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const isValid = await validatePassword(password)
      if (isValid) {
        onSuccess()
      } else {
        setError(true)
      }
    } catch (err) {
      setError(true)
      console.error('Password validation error:', err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="min-h-screen flex items-center justify-center" style={{ backgroundColor: "#7251F4" }}>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Enter Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="password"
              className={input}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
                setError(false)
              }}
              placeholder="Enter password"
              autoFocus
              disabled={isLoading}
            />
          </div>
          {error && (
            <p className="text-red-500 text-sm mb-4">
              Incorrect password. Please try again.
            </p>
          )}
          <button
            type="submit"
            className="w-full bg-[#7251F4] text-white py-2 px-4 rounded hover:bg-[#5a41c3] transition-colors disabled:opacity-50"
            disabled={isLoading}
          >
            {isLoading ? 'Verifying...' : 'Submit'}
          </button>
        </form>
      </div>
    </div>
  )
}
