import { StaticImage } from "gatsby-plugin-image"
import G2Icon from "../../images/svgs/g2_red.svg"
import StarRating from "../../images/svgs/star_yellow.svg"
import useWindowDimensions from "../../utils/useWindowDimensions"

export function Recommend() {
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 1080
  const containerWidth = isMobileUp ? "1080px" : "100%"
  return (
    <div style={{ width: containerWidth }} className="mx-auto mt-20 mb-20 pb-0 md:pb-20">
      <h2 className="hidden md:block text-black text-5xl font-bold leading-tight relative z-20 text-center capitalize">
        <div className="relative mx-2 inline z-10">
          <div className="relative inline-block z-5">
            <span
              style={{ backgroundColor: "#61F2C2" }}
              className="z-1 block absolute left-0 right-0 h-1.5 rounded-xl bottom-1"
            ></span>
            <div className="relative z-2">96% likely</div>
          </div>
        </div>
        to recommend Premagic
      </h2>
      <h2 className="md:hidden text-black text-2xl sm:text-3xl font-bold leading-tight relative z-20 text-center capitalize">
        <div className="relative mx-2 inline z-10">
          <div className="relative inline-block z-5">
            <span
              style={{ backgroundColor: "#61F2C2" }}
              className="z-1 block absolute left-0 right-0 h-1.5 rounded-xl bottom-0"
            ></span>
            <div className="relative z-2">96% likely</div>
          </div>
        </div>
        to recommend Premagic
      </h2>
      <p className="text-black ml-2 text-sm text-center mt-5 md:mt-16 opacity-50 font-medium">
        Rated 4.8 stars
      </p>
      <div className="flex justify-center items-center mb-10">
        <img src={G2Icon} className="mt-1" />
        <img className="ml-3" src={StarRating} />
      </div>
      <div className="flex justify-center flex-wrap gap-0 md:gap-5">
        <StaticImage
          className="w-20 md:w-28 md:w-36 mx-5"
          placeholder="blurred"
          src="../../images/badges/1.png"
          alt="logo"
        />
        <StaticImage
          className="w-20 md:w-28 md:w-36 mx-5"
          placeholder="blurred"
          src="../../images/badges/2.png"
          alt="logo"
        />
        <StaticImage
          className="w-20 md:w-28 md:w-36 mx-5 lg:scale-125"
          placeholder="blurred"
          src="../../images/badges/3.png"
          alt="logo"
        />
        <StaticImage
          className="w-20 md:w-28 md:w-36 mx-5 object-contain"
          placeholder="blurred"
          src="../../images/badges/4.png"
          alt="logo"
        />
        <StaticImage
          className="w-20 md:w-28 md:w-36 mx-5"
          placeholder="blurred"
          src="../../images/badges/5.png"
          alt="logo"
        />
      </div>
    </div>
  )
}

export default Recommend
