import { useLocation } from "@reach/router"
import React, { useEffect, useState } from "react"

import * as styles from "../ds/layout.module.css"
import "../index.css"
import { scrollToElement } from "../utils/BrowserUtils"
import { fetchUserLocation } from "../utils/UserLocationUtils"
import Footer from "./Footer"
import { LayoutContext } from "./layoutContext"
import SEO from "./SEO"

type Props = {
  children: React.ReactNode
  title: string
  description?: string
  metaImage?: string
  meta?: Array<{
    name: string
    content: string
  }>
  hasFooter?: boolean
}

export default function Layout(props: Props) {
  const {
    children,
    title,
    meta,
    metaImage,
    description,
    hasFooter = true,
  } = props
  const location = useLocation()
  const [userLocation, setuserLocation] = useState()
  useEffect(() => {
    fetchUserLocation().then(data => setuserLocation(data))
  }, [])

  useEffect(() => {
    const hash = location.hash
    if (hash) {
      setTimeout(() => {
        scrollToElement(hash, true)

        // if (hash === "#signup") {
        //   focusOnElement(".js-studio-name")
        // }
      }, 300)
    }
  }, [location])

  return (
    <div className={styles.page}>
      <SEO
        title={title}
        meta={meta}
        metaImage={metaImage}
        description={description}
        pathName={location.pathname}
      />
      <div>
        <LayoutContext.Provider
          value={{
            userLocation,
          }}
        >
          <main>{children}</main>
        </LayoutContext.Provider>
        {hasFooter && <Footer />}
      </div>
    </div>
  )
}
