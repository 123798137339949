export enum PRICING_KEY {
  QRCODEDESIGNCOST = "qrCodeDesignCost",
  QRCODEPRINTINGCOST = "qrCodePrintingCost",
  EVENTADVOCACYCOST = "eventAdvocacyCost",
  SPONSORSHIPCOST = "sponsorShipCost",
  RIPPLECOST = "rippleCost",
  DAMCOST = "damCost",
  PHOTOGRAPHERCOST = "photoGrapherCost",
  SPARKCOST = "sparkCost",
  PHOTODISTRIBUTIONCOST = "photoDistributionCost",
  REGISTRATIONMODULECOST = "registrationModuleCost",
  FACECHECKINCOST = "faceCheckinCost",
}

export const initialAddonsCostData = {
  [PRICING_KEY.QRCODEDESIGNCOST]: 0,
  [PRICING_KEY.QRCODEPRINTINGCOST]: 0,
  [PRICING_KEY.EVENTADVOCACYCOST]: 0,
  [PRICING_KEY.SPONSORSHIPCOST]: 0,
  [PRICING_KEY.RIPPLECOST]: 0,
  [PRICING_KEY.DAMCOST]: 0,
  [PRICING_KEY.PHOTOGRAPHERCOST]: 0,
  [PRICING_KEY.PHOTODISTRIBUTIONCOST]: 0,
  [PRICING_KEY.REGISTRATIONMODULECOST]: 0,
  [PRICING_KEY.FACECHECKINCOST]: 0,
}

export const addOnsConfig = [
  {
    label: "Registration module",
    value: "Registration module",
    priceKey: PRICING_KEY.REGISTRATIONMODULECOST,
  },
  {
    label: "Face Check-in",
    value: "Face Check-in",
    priceKey: PRICING_KEY.FACECHECKINCOST,
  },
  {
    label: "Event advocacy posters",
    value: "Event advocacy posters",
    priceKey: PRICING_KEY.EVENTADVOCACYCOST,
  },
  {
    label: "AI based photo distribution",
    value: "AI based photo distribution",
    priceKey: PRICING_KEY.PHOTODISTRIBUTIONCOST,
  },
  {
    label: "Sponsorship module",
    value: "Sponsorship module",
    priceKey: PRICING_KEY.SPONSORSHIPCOST,
  },
  {
    label: "Ripple - Networking module",
    value: "Ripple - Networking module",
    priceKey: PRICING_KEY.RIPPLECOST,
  },
  {
    label: "Sparks - Memories",
    value: "Sparks - Memories",
    priceKey: PRICING_KEY.SPARKCOST,
  },
  {
    label: "DAM",
    value: "DAM",
    priceKey: PRICING_KEY.DAMCOST,
  },
  {
    label: "Photographer ( 1 x 1 )",
    value: "Photographer ( 1 x 1 )",
    priceKey: PRICING_KEY.PHOTOGRAPHERCOST,
  },
  {
    label: "Custom QR code design",
    value: "Custom QR code design",
    priceKey: PRICING_KEY.QRCODEDESIGNCOST,
  },
  {
    label: "QR code printing",
    value: "QR code printing",
    priceKey: PRICING_KEY.QRCODEPRINTINGCOST,
  },
]

export interface PricingInputs {
  registrationCount: number
  photoCount: number
  dayCount: number
}

export interface CalculatePricingParams extends PricingInputs {
  selectedAddOns: any[]
  setCost: (cost: number) => void
  setFinalPrice: (price: number) => void
  setAddOnCost: (costs: any) => void
}

export const validateInputs = (inputs: PricingInputs): boolean => {
  if (!inputs.registrationCount || !inputs.photoCount || !inputs.dayCount) {
    alert("Please fill the required fields.")
    return false
  }
  return true
}

export const calculateTotalPrice = (addOnCost: any, selectedAddOns: any[]) => {
  return selectedAddOns.length
    ? selectedAddOns
        .map(item => addOnCost[item.priceKey])
        .reduce((total, price) => total + price)
    : 0
}
