import React, { useEffect, useState } from "react"
import Select from "react-select"
import Header from "../../components/Header"
import Layout from "../../components/Layout"
import { PasswordScreen } from "../../components/PasswordScreen"
import "../../index.css"
import { formGroup, input } from "../../ds/form.module.css"
import useWindowDimensions from "../../utils/useWindowDimensions"
import { scrollToElement } from "../../utils/BrowserUtils"
import {
  PRICING_KEY,
  addOnsConfig,
  initialAddonsCostData,
  validateInputs,
  calculateTotalPrice,
} from "../../utils/PricingUtils"

export function Index() {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [registrationCount, setRegistrationCount] = useState<number>()
  const [photoCount, setPhotoCount] = useState<number>()
  const [dayCount, setDayCount] = useState<number>()
  const [cost, setCost] = useState<number>(0)
  const [finalPrice, setFinalPrice] = useState(0)
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 1080
  const containerWidth = isMobileUp ? "1080px" : "100%"

  const [addOnCost, setAddOnCost] = useState(initialAddonsCostData)

  const [selectedAddOns, setSelectedAddOns] = useState<any>(addOnsConfig)

  useEffect(() => {
    if (registrationCount && photoCount && dayCount) {
      onCalculate()
    }
  }, [registrationCount, dayCount, photoCount, selectedAddOns])

  if (!isAuthenticated) {
    return <PasswordScreen onSuccess={() => setIsAuthenticated(true)} />
  }

  function onCalculate() {
    if (!validateInputs({ registrationCount, photoCount, dayCount })) {
      return null
    }

    const whatsapp = registrationCount * 3 * 0.88 * 1.2
    const scanSearch =
      (registrationCount +
        500 * (20 / 100) * (((dayCount + 1) * (dayCount + 2)) / 2) +
        registrationCount * 3) *
      0.10855975
    const photoscan = photoCount * 0.10855975
    const storage = photoCount * 0.002861022949 * 25
    const fixedcost = 500 * (dayCount + 1)

    const cogs = 1.1 * (whatsapp + scanSearch + photoscan + storage + fixedcost)

    const totalCost = (cogs / (1 - 80 / 100)) * 1.2
    const photoDistributionCost = Math.max(Math.round(totalCost), 30000)

    setCost(photoDistributionCost)

    // Calculate add-ons

    // Margin 55%

    const newAddonsCostData = {
      [PRICING_KEY.QRCODEDESIGNCOST]: 15000,
      [PRICING_KEY.QRCODEPRINTINGCOST]: 5000,
      [PRICING_KEY.EVENTADVOCACYCOST]: registrationCount * 40 * 1.2,
      [PRICING_KEY.SPONSORSHIPCOST]: registrationCount * (40 / 100) * 60 * 1.2,
      [PRICING_KEY.RIPPLECOST]: Math.max(
        registrationCount * dayCount * (50 / 100) * 25 * 1 * 1.2,
        8500
      ),
      [PRICING_KEY.SPARKCOST]:
        ((registrationCount * (3 * 5 + 0.88)) / (1 - 0.2)) * 1.2,
      [PRICING_KEY.DAMCOST]: (registrationCount * 1.2 * 2 * photoCount) / 1000,
      [PRICING_KEY.PHOTOGRAPHERCOST]: 15000,
      [PRICING_KEY.REGISTRATIONMODULECOST]: registrationCount * 20 * 1.2,
      [PRICING_KEY.PHOTODISTRIBUTIONCOST]: photoDistributionCost,
      [PRICING_KEY.FACECHECKINCOST]: registrationCount * 55 * 1.2,
    }

    const addOnsTotal = calculateTotalPrice(newAddonsCostData, selectedAddOns)
    setFinalPrice(addOnsTotal)

    setAddOnCost(newAddonsCostData)

    scrollToElement("#price", true)
  }

  const ItemElement = props => (
    <div className="flex justify-between py-1">
      <h4 className={props.isBold ? "font-semibold mt-2" : ""}>
        {props.title}
      </h4>
      {props.value && (
        <h4 className={`${props.isBold ? "font-semibold mt-2" : ""}`}>
          {props.value}
        </h4>
      )}
      {props.cost && (
        <h4 className={`${props.isBold ? "font-semibold mt-2" : ""}`}>
          ₹{Math.round(props.cost).toLocaleString("en-US")}
        </h4>
      )}
    </div>
  )


  return (
    <Layout title="India">
      <Header />
      <div style={{ backgroundColor: "#7251F4" }}>
        <div className="mx-auto py-20 w-full md:w-[1080px]">
          <div className="md:flex">
            <div className="w-full md:px-0 px-10 md:w-1/2">
              <h1 className="text-white font-semibold text-3xl">
                Pay Per Event - Pricing Calculator
              </h1>
              <p className="text-white mt-2 text-sm mb-8">
                This pricing is recommended for multiple-day events.
              </p>
              <div className={`mb-5 ${formGroup}`}>
                <p className="text-white font-semibold text-base mb-1">
                  Total number of Attendees
                  <span className="text-red-400 ml-1">*</span>
                </p>
                <input
                  required
                  type="number"
                  name="registration_count"
                  autoFocus
                  placeholder="eg: 600"
                  onChange={e => setRegistrationCount(Number(e.target.value))}
                  className={input}
                  tabIndex={0}
                />
              </div>
              <div className={`mb-5 ${formGroup}`}>
                <p className="text-white font-semibold text-base mb-1">
                  Total number of Photos
                  <span className="text-red-400 ml-1">*</span>
                </p>
                <input
                  required
                  type="number"
                  name="photos_count"
                  placeholder="eg: 1500"
                  onChange={e => setPhotoCount(Number(e.target.value))}
                  className={input}
                />
              </div>
              <div className={`mb-5 ${formGroup}`}>
                <p className="text-white font-semibold text-base mb-1">
                  Number of Days<span className="text-red-400 ml-1">*</span>
                </p>
                <input
                  required
                  type="number"
                  name="days_count"
                  placeholder="eg: 3 Days"
                  onChange={e => setDayCount(Number(e.target.value))}
                  className={input}
                />
              </div>
              <div className={`mb-5 ${formGroup}`}>
                <p className="text-white font-semibold text-base mb-1">
                  Add-ons{" "}
                </p>
                <Select
                  defaultValue={addOnsConfig}
                  isMulti
                  name="colors"
                  options={addOnsConfig}
                  classNamePrefix="select"
                  onChange={selected => setSelectedAddOns(selected)}
                />
              </div>
              {/* <button
                  className={`${btn} ${btnPrimary} bg-white mt-10`}
                  onClick={onCalculate}
                  type="submit"
                  >Calculate
                  <div className={`ml-5`}>
                      <WhiteArrowRight />
                  </div>
              </button> */}
            </div>
            <div
              className="w-full md:w-1/2 flex flex-col md:justify-center items-center mt-10 md:mt-0 md:px-10 px-10"
              id="price"
            >
              {/* <img src={dollarIcon} alt='dolar' className='md:-mt-10' width={100} /> */}
              {/* <h2 className='text-white font-bold'>₹{cost.toLocaleString('en-IN')}</h2>  */}
              <div className="bg-white w-full h-full rounded-xl p-10">
                <ItemElement title="Attendees" value={registrationCount} />
                <ItemElement title="Photos" value={photoCount} />
                <ItemElement title="Days" value={dayCount} />
                {/* <ItemElement title= 'AI-based photo distribution' cost={cost} isBold /> */}
                <div className="my-10"></div>
                {selectedAddOns.length ? (
                  <div>
                    {/* <div className='flex justify-between items-center my-5'>
                        <div className='p-2 text-white w-fit' style={{ backgroundColor: '#FF6550' }}>Add-ons</div>
                        <div className='p-2 w-fit text-2xl' style={{ color: '#FF6550' }}>+</div>
                      </div> */}
                    {selectedAddOns.map(item => (
                      <ItemElement
                        title={item.label}
                        cost={addOnCost[item.priceKey]}
                      />
                    ))}
                    <div className="py-5"></div>
                    <ItemElement title="Total Cost" cost={finalPrice} isBold />
                  </div>
                ) : (
                  ""
                )}
                <p className="text-xs mt-5 text-zinc-500">*Excluding GST/VAT</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Index
