
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Header from "../components/Header"
import Layout from "../components/Layout"
import { btn, btnPrimary } from "../ds/button.module.css"
import { WhiteArrowRight } from "../ds/Shapes"

export default function index() {
  return (
    <Layout
      title="Sell-out your Events with Customizable Event Marketing Posters"
      description="Achieve 10X more referrals through each attendee using personalized event marketing posters."
    >
      <div className="">
        <div className="" style={{ backgroundColor: "#7251F4" }}>
          <Header isWhiteLogo />
        </div>

        <section className="py-10" style={{ backgroundColor: "#7251F4" }}>
          <div className="px-5 md:px-0 mx-auto md:w-[1080px]">
            <div className="md:flex items-center h-screen">
              <div className="md:w-1/2 flex flex-col justify-center text-left">
                {/* <h1 className="text-3xl md:text-5xl font-bold mb-4 text-white"><span style={{ color: '#61F2C2' }}>DOUBLE</span> YOUR <br />ATTENDEES</h1> */}
                <h1 className="text-3xl md:text-5xl font-bold mb-4 text-white">
                  Maximize your sponsors’ ROI
                </h1>
                <p className="text-white">
                  Drive more value for your sponsors & <br/> exhibitors while  <span style={{ color: "#61F2C2" }}>boosting event revenue</span>  with <br/> enhanced ROI offerings.
                </p>
                <Link
                  to="/request-a-demo/"
                  className={`${btn} ${btnPrimary} text-xs mt-10 md:mt-32 mb-10 md:mb-0 relative w-max group`}
                >
                  <span className="mr-4 ml-2">Get started</span>
                  <WhiteArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2 mr-2" />
                </Link>
              </div>
              <div className="md:w-1/2 md:ml-0 relative">
                <div className="md:absolute -left-60 -mx-10 md:mx-0 md:w-[900px] -top-8">
                  <StaticImage src="../images/advocacy_hero.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="md:pt-10">
          <div className="px-5 md:px-0 mx-auto md:w-[1080px]">
            <div className="text-center py-20">
            <h4 className="font-bold text-4xl mb-5" style={{ color: "#7251F4" }}>Give them more reasons to <br/>
            sponsor your event again.</h4>
            <p>Premagic's  sponsor content feature allows sponsors and<br/> exhibitors to run ads in your own specialized ad space!<br/> Get more exposure, new leads, and sales today!</p>
            </div>

            <div className="flex flex-col md:flex-row flex-col-reverse">
              <div className="md:w-1/2 flex flex-col justify-center text-left md:pr-10 md:mt-0">
                {/* <h4
                  className="font-bold text-2xl mb-5"
                  style={{ color: "#7251F4" }}
                >
                  How it works
                </h4> */}
                <h1
                  className="text-3xl md:text-4xl font-bold md:leading-snug mb-4"
                  style={{ color: "#7251F4" }}
                >
                  {/* Grow your event <span style={{ color: "#FF6550" }}>10x</span>{" "}
                  with event marketing posters */}
                  In-Gallery Logos and <br/> Videos
                </h1>
                <p>
                Insert sponsored images or videos between attendee photo galleries, while adding multiple watermarks to attendee photos for increased brand visibility.
                </p>
              </div>
              <div className="md:w-1/2 md:pl-10 mt-10 md:mt-0 -mr-10 md:mr-0">
                <StaticImage
                  src="../images/how_it_works_image.png"
                  height={500}
                  alt=""
                />
              </div>
            </div>
            <div className="md:flex mt-20">
              <div className="md:w-1/2 md:pr-10">
                <StaticImage src="../images/feature1.png" alt="" />
              </div>
              <div className="md:w-1/2 flex flex-col justify-center text-left mt-10 md:mt-0 md:pl-10">
                <h1
                  className="text-3xl md:text-4xl font-bold md:leading-snug mb-2"
                  style={{ color: "#7251F4" }}
                >
                 Clickable Sponsor Content

                </h1>
                <p className="md:pr-32">
                Drive direct traffic to Sponsor websites by embedding hyperlinked logos, images and videos in the event photo galleries.
                </p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row mt-20 md:my-20 flex-col-reverse">
              <div className="md:w-1/2 flex flex-col justify-center text-left md:pr-10 mt-10 md:mt-0">
                <h1
                  className="text-3xl md:text-4xl font-bold md:leading-snug mb-2"
                  style={{ color: "#7251F4" }}
                >
                Multi-Tier Sponsorship on Gallery
                </h1>
                <p className="md:pr-32">
                Showcase sponsors across different tiers with customizable logo placements and categorizations within
                the galleries.
                </p>
              </div>
              <div className="md:w-1/2 md:pl-10">
                <StaticImage src="../images/feature2.png" alt="" />
              </div>
            </div>
            <div className="md:flex mb-20 mt-20 md:mt-0">
              <div className="md:w-1/2 md:pr-10">
                <StaticImage src="../images/feature3.png" alt="" />
              </div>
              <div className="md:w-1/2 flex flex-col justify-center text-left md:pl-10 mt-10 md:mt-0">
                <h1
                  className="text-3xl md:text-4xl font-bold md:leading-snug mb-2"
                  style={{ color: "#7251F4" }}
                >
                Track Post-Event 
                Sponsor Impressions
                </h1>
                <p className="md:pr-32">
                View and export comprehensive insights and reports on your sponsorship performance through the analytics dashboard.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="my-20">
          <div className="px-5 md:px-0 mx-auto flex py-10 items-center flex-wrap md:w-[1080px]">
          <div className="w-1/2 md:w-1/4 text-center">
              <StaticImage
                class="w-24"
                src="../images/metric_icon4.png"
                alt=""
              />
              <h4
                style={{ color: "#FF6550" }}
                className="text-4xl font-bold my-5"
              >
                1.84%
              </h4>
              <p>
                increase in <br /> Click-through rates
              </p>
            </div>
            <div className="w-1/2 mb-20 md:mb-0 md:w-1/4 text-center">
              <StaticImage
                class="w-24"
                src="../images/memory-retention.png"
                alt=""
              />
              <h4
                style={{ color: "#FF6550" }}
                className="text-4xl font-bold my-5"
              >
                42%
              </h4>
              <p>
                memory 
                <br />
                retention
              </p>
            </div>
            <div className="w-1/2 mb-20 md:mb-0 md:w-1/4 text-center">
              <StaticImage
                class="w-20"
                src="../images/roi.png"
                alt=""
              />
              <h4
                style={{ color: "#FF6550" }}
                className="text-4xl font-bold my-5"
              >
                70%
              </h4>
              <p>
                increased ROI
                <br />
                for sponsors
              </p>
            </div>
            <div className="w-1/2 md:w-1/4 text-center">
              <StaticImage
                class="w-20"
                src="../images/metric_icon1.png"
                alt=""
              />
              <h4
                style={{ color: "#FF6550" }}
                className="text-4xl font-bold my-5"
              >
                2X
              </h4>
              <p>
                more
                <br />
                engagement
              </p>
            </div>
            
          </div>
        </section>
        <section
          className="py-20 md:py-0"
          style={{ backgroundColor: "#7251F4" }}
        >
          <div className="px-5 md:px-0 mx-auto md:flex py-10 items-center md:w-[1080px]">
            <div className="md:w-1/2 flex flex-col justify-center relative mb-20 md:mb-0">
              <StaticImage class="" src="../images/posters.png" alt="" />
            </div>
            <div className="md:w-1/2">
              <h4 className="text-white text-center text-2xl">
                <span style={{ color: "#61F2C2" }}>“</span>Thanks to the in-gallery sponsor videos, our sponsors are seeing real results. 
                They couldn't be happier.<span style={{ color: "#61F2C2" }}>”</span>
              </h4>
              <h5
                className="text-2xl text-center mt-10"
                style={{ color: "#61F2C2" }}
              >
                Dana Sakr
              </h5>
              <p className="text-white text-xl text-center">
                Marketing Manager, Step
              </p>
            </div>
          </div>
          
        </section>
        <div className="rounded-2xl w-1/2 mt-20 mx-auto py-12 px-10 mb-10 flex items-center justify-between" style={{ backgroundColor: "#7251F4" }}>
            <div>
              <h3 className="text-4xl font-extrabold text-white">Ready for turn <br/><span style={{ color: "#61F2C2" }}>brand visibility<br/> into results?</span></h3>
              <p className="text-white mt-4">Turn brand exposure into measurable <br/> impact.</p>
            </div>
            <Link
                  to="/request-a-demo/"
                  className={`${btn} ${btnPrimary} text-xs mb-10 md:mb-0 relative w-max group`}
                >
                  <span className="mr-4 ml-2">Show me how</span>
                  <WhiteArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2 mr-2" />
                </Link>
            </div>
          </div>
        {/* <RequestDemo /> */}
    </Layout>
  )
}
