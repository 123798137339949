import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Flickity from "react-flickity-component"
import {
  btn,
  btnPrimary
} from "../../ds/button.module.css"
import { WhiteArrowRight } from "../../ds/Shapes"
import useWindowDimensions from "../../utils/useWindowDimensions"

const featuresData = [
  {
    title: "AI-powered Photo Distribution",
    desciption:
      "Our AI-powered solution finds and shares the best shots instantly, letting your attendees relive their favorite moments—and share them across their networks.",
    bgColor: "#F5F2FF",
    titleColor: "#1F0F61",
    image: (
      <StaticImage
        objectFit="contain"
        className=""
        width={250}
        src="../../images/features/feature_4.png"
        alt="client magic motion media"
      />
    ),
  },
  {
    title: "Event Advocacy Posters",
    desciption:
      "Automatic, personalized posters for attendees, speakers, and exhibitors.",
    bgColor: "#EAFFF8",
    titleColor: "#109A6D",
    image: (
      <StaticImage
        objectFit="contain"
        className=""
        width={250}
        src="../../images/features/feature_2.png"
        alt="client magic motion media"
      />
    ),
  },
  {
    title: "Registration with Face Check-in",
    desciption:
      "Streamlined registration with multi-zone face check-in, badge generation & swag tracking",
      bgColor: "#F2FFFE",
      titleColor: "#005C55",
      image: (
        <StaticImage
          objectFit="contain"
          className=""
          width={250}
          src="../../images/features/feature_8.png"
          alt="client magic motion media"
        />
      ),
  },
  {
    title: "Avatar Studio",
    desciption:
      "Engage your attendees with fun and exciting personalized AI avatars.",
    bgColor: "#FFF7E8",
    titleColor: "#613F00",
    image: (
      <StaticImage
        objectFit="contain"
        className=""
        width={250}
        src="../../images/features/feature_3.png"
        alt="client magic motion media"
      />
    ),
  },
  {
    title: "Ripple - Smart Networking",
    desciption: "Help attendees easily connect with others by tapping on any face in the photo gallery.",
    bgColor: "#FFF1EF",
    titleColor: "#E15D4B",
    image: (
      <StaticImage
        objectFit="contain"
        className=""
        width={250}
        src="../../images/features/feature_1.png"
        alt="client magic motion media"
      />
    ),
  },
  {
    title: "Sponsored Content",
    desciption: "Elevate brand awareness and exposure for you and your sponsors.",
    bgColor: "#F3FFD0",
    titleColor: "#324100",
    image: (
      <StaticImage
        objectFit="contain"
        className=""
        width={250}
        src="../../images/features/feature_5.png"
        alt="client magic motion media"
      />
    ),
  },
  {
    title: "Sparks - UGC Booster",
    desciption:
      "Extend the life of your event and revive engagement with curated events highlights.",
    bgColor: "#F2FFFE",
    titleColor: "#005C55",
    image: (
      <StaticImage
        objectFit="contain"
        className=""
        width={250}
        src="../../images/features/feature_6.png"
        alt="client magic motion media"
      />
    ),
  },
  {
    title: "Digital Asset Management",
    desciption: "Store, organize and search for past photos using face recognition",
    bgColor: "#FFF1EF",
    titleColor: "#E15D4B",
    image: (
      <StaticImage
        objectFit="contain"
        className=""
        width={250}
        src="../../images/features/feature_7.png"
        alt="client magic motion media"
      />
    ),
  },
  
]

export function Features() {
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 1080
  const containerWidth = isMobileUp ? "1080px" : "100%"

  const flickityOptions = {
    // autoPlay: 2000,
    // wrapAround: true,
    // selectedAttraction: 0.01,
    friction: 0.2,
    cellAlign: "left",
    contain: true,
    // contain: true,
    // pageDots: true,
    // prevNextButtons: true,
    // pauseAutoPlayOnHover: false
  }

  return (
    <section>
      <div
        className="mx-auto mt-10 mb-20 md:mb-20"
        style={{ width: containerWidth }}
      >
        <h2 className="block text-black text-2xl sm:text-3xl md:text-5xl font-bold text-center leading-tight md:leading-tight relative z-20 capitalize">
        From Pre-Event to Post-Event <br/>
        we’ve got
          <div className="relative mx-2 inline z-10">
            <div className="relative inline-block z-5">
              <span
                style={{ backgroundColor: "#61F2C2" }}
                className="z-1 block absolute left-0 right-0 h-1.5 rounded-xl bottom-1"
              ></span>
              <div className="relative z-2">you covered</div>
            </div>
          </div>
        </h2>
        <p className="mt-4 sm:mt-10 mb-14 text-base md:text-lg opacity-60 text-center px-5 md:px-0">
        Comprehensive solutions to elevate your event marketing strategies
        </p>
        <div className="relative no-scrollbar" style={{ height: "400px" }}>
          <div className="">
            <div className="">
              <Flickity
                className="carousel outline-none" // default ''
                // elementType="div"
                options={flickityOptions} // takes flickity options {}
                // disableImagesLoaded={false} // default false
                reloadOnUpdate // default false
                // static // default false
              >
                {featuresData.map(item => (
                  <div
                    className="px-2"
                    style={{ width: "328px", height: "400px" }}
                  >
                    <div
                      className="rounded-xl h-full flex flex-col"
                      style={{ backgroundColor: item.bgColor }}
                    >
                      <div className="p-8 pb-0">
                        <h2
                          className="font-bold text-xl md:text-2xl text-black"
                          style={{ color: item.titleColor }}
                        >
                          {item.title}
                        </h2>
                        <p className="text-sm mt-5">{item.desciption}</p>
                      </div>
                      <div className="rounded-xl overflow-hidden text-right mt-auto">
                        {item.image}
                      </div>
                    </div>
                  </div>
                ))}
              </Flickity>
            </div>
          </div>
        </div>
        <div className="text-center">
          <Link
            to="/request-a-demo/"
            className={`${btn} ${btnPrimary} text-xs relative w-max group mt-10`}
          >
            <span className="mr-4 ml-2">Get a Demo</span>
            <WhiteArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2 mr-2" />
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Features
