import { Link } from "gatsby"
import {
  btn,
  btnPrimary
} from "../../ds/button.module.css"
import { WhiteArrowRight } from "../../ds/Shapes"
import useWindowDimensions from "../../utils/useWindowDimensions"

export function MagicWithUs() {
  const { width } = useWindowDimensions()
  const isMobileUp = width >= 1080
  const containerWidth = isMobileUp ? "1080px" : "100%"
  if (isMobileUp) {
    return (
      <section className="py-10" style={{ backgroundColor: "#7251F4" }}>
        <div
          className="px-5 md:px-0 mx-auto flex justify-between items-center"
          style={{ width: containerWidth }}
        >
          <div className="w-1/2 flex justify-between relative">
            <h2 className="text-white font-semibold text-4xl relative z-20 leading-snug">
              Let’s Begin Your Journey
              <br /> to
              <div className="relative mx-2 inline z-10">
                <div className="relative inline-block z-5">
                  <span
                    style={{ backgroundColor: "#61F2C2" }}
                    className="z-1 block absolute left-0 right-0 h-1.5 rounded-xl bottom-1"
                  ></span>
                  <div className="relative z-2">Epic</div>
                </div>
              </div>
              Events.
            </h2>
          </div>
          <Link
              to="/request-a-demo/"
              className={`${btn} ${btnPrimary} text-xs mt-5 relative w-max `}
            >
              <span className="mr-2">Request for demo</span>
              <WhiteArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2" />
            </Link>
        </div>
      </section>
    )
  }
  return (
    <section className="" style={{ backgroundColor: "#7251F4" }}>
      <div className="pm-container mx-auto py-20">
        <div className="flex flex-col justify-center relative">
          <h2 className="text-white font-semibold text-4xl relative z-20 leading-snug">
            Let’s Begin Your Journey to
            <div className="relative mx-2 inline z-10">
              <span
                style={{ backgroundColor: "#61F2C2" }}
                className="z-1 block absolute left-0 right-0 h-1.5 rounded-xl bottom-1"
              ></span>
              <div className="relative inline-block z-5">Epic</div>
            </div>
            Events.
          </h2>
          <p className="text-base text-white my-5">
            Our team is all set to show you how to take <br /> your events to
            new heights.
          </p>
          <Link
            to="/request-a-demo/"
            className={`${btn} ${btnPrimary} text-xs mt-5 relative w-max `}
          >
            <span className="mr-2">Request for demo</span>
            <WhiteArrowRight className="transition duration-300 ease-in-out group-hover:translate-x-2" />
          </Link>
        </div>
      </div>
    </section>
  )
}

export default MagicWithUs
