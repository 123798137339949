import { StaticImage } from "gatsby-plugin-image";
import { CheckIconBrown, CheckIconPink, CheckIconPurple, CheckIconViolet } from "../ds/SvgIcons";

export const homeMainFeatures = [
  {
    image: <StaticImage src="../images/main-features/share.png" alt="Share" />,
    mob_image:<StaticImage src="../images/main-features/hack_01.png" alt="" />,
    title: `Deliver event photos <span style="color: #7252F0;">the same day</span>,not days or weeks later.`,
    descriptions: 'Turn photos into marketing goldmines that sell your next event before it even happens.',
    link:'/smart-photo-distribution-for-seamless-events/'
  },
  {
    image: <StaticImage src="../images/main-features/attending.png" alt="Attending" />,
    mob_image:<StaticImage src="../images/main-features/hack_02.png" alt="" />,
    title: `Create <span style="color: #7252F0;">personalized</span> marketing <br/> posters for everyone.`,
    descriptions: 'Drive 2x registrations by empowering every attendee, speaker, and sponsor to become an advocate for your event.',
    link:'/create-event-marketing-posters'
  },
  {
    image: <StaticImage src="../images/main-features/share-easy.png" alt="Super Hero" />,
    mob_image:<StaticImage src="../images/main-features/hack_03.png" alt="" />,
    title: `Give your audience fun <br/><span style="color: #7252F0;">share-worthy</span> content.`,
    descriptions: 'Keep your brand top of mind with quirky, personalized AI avatars attendees can’t wait to share.',
    link:'/request-a-demo'
  },
  {
    image: <StaticImage src="../images/main-features/face-check.png" alt="Face" />,
    mob_image:<StaticImage src="../images/main-features/hack_04.png" alt="" />,
    title: `Cut down on wait time by 50% <br/> with <span style="color: #7252F0;">face check-in</span>`,
    descriptions: 'Get your attendees into your event quickly and effortlessly with secure QR code and Face ID check-ins.',
    link:'/simplify-event-registration'
  },
];

export const mainFeatures = [
  {
    image: <StaticImage src="../images/main-features/001.png" alt="" />,
    title: "Turn your attendees into <br/> content creators",
    icon: CheckIconPink,
    descriptions: [
      "Your audience leaves the event with content that will keep the conversations going long after the event wraps up— and not just another branded t-shirt.",
      "Let your audience take the wheel in creating event content. Tap into the power of UGC (user-generated content) to make the most out of your investment.",
    ],
  },
  {
    image: <StaticImage src="../images/main-features/002.png" alt="" />,
    title: "Show your sponsor’s brands<br/> some love",
    icon: CheckIconViolet,
    descriptions: [
      "Give your sponsor brands the spotlight they deserve. Attract new sponsors and retain current ones by giving them a front-row seat to engage with a broader audience.",
      "With a prime platform to spotlight sponsor brands and a custom call-to-action, your sponsors are sure to come back for more.",
    ],
  },
  {
    image: <StaticImage src="../images/main-features/003.png" alt="" />,
    title: "Level up your attendee <br/> engagement",
    icon: CheckIconBrown,
    descriptions: [
      "Don’t just host your attendees - engage them. And leave the outdated engagement strategies at the door.",
      "With real-time photo distribution, you can drive audience engagement and bump up your event's visibility while creating unique event experiences like never before.",
    ],
  },
  {
    image: <StaticImage src="../images/main-features/004.png" alt="" />,
    title: "Transform your event data <br/> into gold",
    icon: CheckIconPurple,
    descriptions: [
      "Numbers don’t lie – Find out what clicked and what didn’t with our analytic tools.",
      "Do more with deep insights to elevate your strategy. Make data-driven decisions to deliver events that generate ROI that keeps on giving.",
    ],
  },
]