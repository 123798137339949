
import CaseStudy, { CASE_STUDY_TYPES } from '../../../components/case-study/CaseStudy';
import Header from '../../../components/Header';
import Layout from '../../../components/Layout';
import ThumbnailImage from '../../../images/thumbnails/g2_thumb.png';

export function Index() {
  return (
    <Layout 
    title="Learn how G2 achieved 4x more registrations with Premagic"
    description="G2 Live brings together B2B SaaS leaders, venture capitalists, and industry experts who turn market shifts into launches for growth and innovation."
    metaImage={ThumbnailImage}
    >
      <Header />
      <CaseStudy type={CASE_STUDY_TYPES.G2_LIVE} />
    </Layout>
  );
}

export default Index;